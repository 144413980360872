import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      A dreamer is one who can only find his way by moonlight, and his
      punishment is that he sees the dawn before the rest of the world.
    </p>
    <p>Oscar Wilde - Irish writer - 1854-1900</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default IndexPage
